import React from 'react';

/**
 * @returns {JSX.Element}
 * @constructor
 */
const HeroSupport = () => (
  <section className='hero hero--support'>
    <div className='container'>
      <h1 className='hero__title'>
        Take a deep breath ...
        <br />
        {' '}
        help is on the way.
      </h1>
      <div className='hero__description'>
        <p>
          Our customers buy Mila for the health of their loved ones and we take that responsibility seriously. We’re
          here to support you, 24/7.
        </p>
      </div>
      <div className='btn-group'>
        <a className='btn btn--icon' href='mailto:support@milacares.com?subject=I have a question about Mila'>
          <svg className='icon icon-envelope'>
            <use xlinkHref='#icon-envelope' />
          </svg>
          Send us an e-mail
        </a>
      </div>
    </div>
  </section>
);

export default HeroSupport;
